<template>
    <div>
        <template v-if="setting.type === 'layout'">
            <Layout v-model="data[setting.id]" />
        </template>
		<template v-else-if="setting.type === 'normalText'">
			<a-input v-model="data[setting.id]" />
		</template>
        <template v-else-if="setting.type === 'text'">
			<RichText v-model="data[setting.id]" :format="false" builder="web" />
		</template>
        <template v-else-if="setting.type === 'number'">
            <a-input-number v-model="data[setting.id]" class="w-full" />
        </template>
        <template v-else-if="setting.type === 'linkText'">
            <a-input v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'range'">
            <div class="w-full dF">
                <a-slider style="flex: 1;" :vertical="Boolean(setting.vertical)" :style="`height:${setting.vertical? '100px' : 'auto'}`" :tipFormatter="e => setting.suffix ?  e + setting.suffix : e" v-model="data[setting.id]" :min="setting.min || 1" :max="setting.max || 100" />
                <span class="ml-3" style="width: 40px;"> {{data[setting.id]}} {{setting.suffix ? setting.suffix : ''}}</span>
            </div>
        </template>
        <template v-else-if="setting.type === 'form'">
            <FormSection v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'options'">
            <a-select v-model="data[setting.id]" :options="setting.options" />
        </template>
        <template v-else-if="setting.type === 'multioptions'">
            <a-select v-model="data[setting.id]" mode="multiple" :options="setting.options" />
        </template>
        <template v-else-if="setting.type === 'font'">
            <a-select v-model="data[setting.id]" :options="fontList" />
        </template>
        <template v-else-if="setting.type === 'switch' || setting.type === 'checkbox' || setting.type === 'boolean'">
            <a-switch v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'image'">
            <ImageBoxSelector v-model="data[setting.id]" :removable="true" />
        </template>
        <template v-else-if="setting.type === 'video'">
            <ImageBoxSelector v-model="data[setting.id]"  type=".mp4" />
        </template>
        <template v-else-if="setting.type === 'instance'">
            <InstanceSelector v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'instancesLogo'">
            <InstanceLogos v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'portfolioSet'">
            <PortfolioSet v-model="data[setting.id]" />
        </template>
		<template v-else-if="setting.type === 'gallerySet'">
            <GallerySet v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'expandable'">
            <Expandable v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'image-with-alt'">
            <ImageBoxAlt v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'imageObj'">
            <ImageObj v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'modelConfig'">
            <ModelConfigurator v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'images'">
            <ImageBoxSelectorGroup v-model="data[setting.id]" :setting="setting" />
        </template>
        <template v-else-if="setting.type === 'richtext'">
            <RichText v-model="data[setting.id]" :format="true" builder="web" />
        </template>
        <template v-else-if="setting.type === 'color'">
            <ColorPicker v-model="data[setting.id]" :defaults="project && project.id && project.colors._theme || []" :colors="project && project.id && project.colors || []" :transparent="true" />
        </template>
        <template v-else-if="setting.type === 'button'">
            <ButtonEditor v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'link'">
            <LinkEditor v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'tag'">
            <TagSelector v-model="data[setting.id]" />
        </template>
		<template v-else-if="setting.type === 'menu'">
            <NavigationMenu v-model="data[setting.id]" :project="project" />
        </template>
		<template v-else-if="setting.type === 'eventObj'">
			<EventSelector v-model="data[setting.id]" />
		</template>
		<template v-else-if="setting.type === 'custom'">
			<a-textarea v-model="data[setting.id]" :rows="8"></a-textarea>
		</template>
		<template v-else-if="setting.type === 'siteplanStatus'">
			<div style="padding: 10px; border: 1px solid #eee;">
				<span slot="label">
					Hide Dots
					<a-tooltip overlayClassName="change-tooltip-color" title="Choose whether to hide or show status dots.">
						<a-icon type="question-circle-o" style="color: var(--orange);" />
					</a-tooltip>
				</span>
				<div style="line-height: 1; margin-bottom: 1em;" v-if="data[setting.id]">
					<a-switch v-model="data[setting.id].hideDots" />
				</div>

				<template v-if="!data[setting.id].hideDots">
					<span slot="label">
						Pulsate Dots
					</span>
					<div style="line-height: 1; margin-bottom: 1em;">
						<a-switch v-model="data[setting.id].pulsateDots" />
					</div>
				</template>

				<template v-if="data[setting.id].hideDots">
					<span slot="label">
						Remove Status Color Overlay
						<a-tooltip overlayClassName="change-tooltip-color" title="Choose whether to hide or show the status color blocks.">
							<a-icon type="question-circle-o" style="color: var(--orange);" />
						</a-tooltip>
					</span>
					<div style="line-height: 1; margin-bottom: 1em;">
						<a-switch v-model="data[setting.id].hideStatus" />
					</div>
				</template>

				<template v-if="data[setting.id].hideDots && !data[setting.id].hideStatus">
					<span slot="label">
						Adjust Lot # Font Size
					</span>
					<div class="w-full dF" style="line-height: 1; margin-bottom: 1em;">
						<a-slider style="flex: 1; height: auto;" v-model="data[setting.id].fontSize" :min="1" :max="10" />
					</div>
				</template>
			</div>
        </template>
    </div>
</template>

<script>
import {setProp} from 'bh-mod'

export default {
    name:'SectionEditor',
    props:['data','setting','font-list'],
    computed:{
        project(){
            return this.$store.state.appData.builder.currProject
        }
    },
    components:{
        FormSection: () => import('@/components/site/FormSection'),
        ImageBoxSelector: () => import('bh-mod/components/common/ImageBoxSelector'),
        ColorPicker: () => import('@/components/site/ColorPicker'),
        ImageBoxAlt: () => import (`bh-mod/components/common/ImageBoxAlt`),
        ImageObj: () => import (`@/components/site/ImageObj`),
        ImageBoxSelectorGroup: () => import('bh-mod/components/common/ImageBoxSelectorGroup'),
		RichText: () => import('@/components/common/RichText'),
        ButtonEditor: () => import('@/components/site/ButtonEditor'),
        ModelConfigurator: () => import('@/components/site/ModelConfigurator'),
        LinkEditor: () => import('@/components/site/LinkEditor'),
        InstanceSelector: () => import('@/components/site/InstanceSelector'),
        InstanceLogos: () => import('@/components/site/InstanceLogos'),
        PortfolioSet: () => import('@/components/site/PortfolioSet'),
		GallerySet: () => import('@/components/site/GallerySet'),
        Expandable: () => import('@/components/site/Expandable'),
        Layout: () => import('@/components/site/LayoutSetting'),
        TagSelector: () => import('@/components/site/TagSelector'),
		EventSelector: () => import('@/components/site/EventSelector'),
		NavigationMenu: () => import('@/components/site/NavigationMenu'),
    },
}
</script>

<style>

</style>
